import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { KendoModule } from 'src/app/modules/kendo.module';
import { GroupModule } from '@progress/kendo-angular-grid';
import { UserManagerComponent } from '../components/user-manager/user-manager.component';
import { UserManagerDetailsComponent } from '../components/user-manager/user-manager-details/user-manager-details.component';

@NgModule({
  declarations: [
    UserManagerComponent,
    UserManagerDetailsComponent
  ],
  imports: [
    BrowserModule,
    KendoModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    GroupModule,
    UserManagerComponent,
    UserManagerDetailsComponent
  ],

  providers: [],
})
export class MMSUserModule { }
