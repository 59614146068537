import { Component, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { UserDto } from '../../models/user.model';
import { CommonService } from 'src/app/core/services/common.service';
import { MMSUserService } from '../../services/mms-user/mms-user.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesDto } from '../../models/roles.model';
import { MMSUserRoutingConstants } from 'src/app/core/constants/MMS_User/mms-user-routing.constants';
import { GroupsDto } from '../../models/groups.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent implements OnInit {
  searchedText: string = '';
  checked: boolean = false;
  mmsUserList: UserDto[] = [];
  gridData: UserDto[] = [];
  fileName: string = 'MMSUser.xlsx';
  constructor(public commonService: CommonService, private mmsUserService: MMSUserService, private dialogService: DialogService,
    private route: ActivatedRoute, private router: Router) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.searchedText = stateData['searchedText'];
    }

  }
  ngOnInit(): void {
    if (this.isAdmin()) {
      this.getMMSUserList();
    }
    else {
      this.router.navigate(['../MMS'], { relativeTo: this.route });
    }
  }

  isAdmin(): boolean {
    let roles: string = this.commonService.getUserRoles();
    if (!this.commonService.isNullOrEmptyOrUndefinedOnly(roles)) {
      let user_roles: string[] = roles.split(',').filter((role: string) => role === RoleConstant.Admin);
      if (user_roles.length > 0)
        return true;
      else
        return false;
    }
    return false;
  }
  getMMSUserList() {
    this.mmsUserService.getMMSUserList().subscribe((userList: UserDto[]) => {
      this.mmsUserList = userList;
      this.getGridData(this.checked);
    });
  }

  getGridData(checked: boolean) {
    this.gridData = this.commonService.setupGobalSearch(this.mmsUserList, checked, 'cai', this.searchedText, 'name')
  }

  getRoleNames(element: RolesDto[], dataItem: UserDto): string {
    if (element && element.length > 0) {
      const arr: string[] = [];
      element.forEach(el => { arr.push(<string>el["name"]) }
      )
      dataItem.rolesArr = arr.join(', ');
      return arr.join(', ');
    }
    return '';
  }

  getGroupNames(element: GroupsDto[], dataItem: UserDto): string {
    if (element && element.length > 0) {
      const arr: string[] = [];
      element.forEach(el => { arr.push(<string>el["name"]) }
      )
      dataItem.accessGroupsArr = arr.join(', ');
      return arr.join(', ');
    }
    return '';
  }

  ImportExcel() {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });

    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let sarUsers: UserDto[] = arr.map((item: any) => {
          return this.mapUserDtoFromExcel(item);
        });
        this.importExcelData(sarUsers);
      }
    });
  }

  mapUserDtoFromExcel(item: any): UserDto {
    let sarUser: UserDto = new UserDto();
    sarUser.id = 0;
    sarUser.name = item['Name'].toString();
    sarUser.cai = item['CAI'].toString();
    sarUser.isActive = item['Is Active'];
    sarUser.businessUnitName = item['Business Unit'];
    sarUser.accessGroupsDto = this.mapGroupDtoFromExcel(item);
    sarUser.rolesDto = this.mapRoleDtoFromExcel(item);
    sarUser.modifiedBy = item['Modified By'];
    sarUser.createdBy = item['Created By'];
    sarUser.createdDateTime = new Date;
    return sarUser;
  }

  mapGroupDtoFromExcel(item: any): GroupsDto[] {
    var groupNames = item['Group Names'].split(',');
    var groupDtos: GroupsDto[] = [];
    groupNames.forEach(function (name: string) {
      var groupDto = new GroupsDto();
      groupDto.name = name;
      groupDtos.push(groupDto);
    });
    return groupDtos;
  }

  mapRoleDtoFromExcel(item: any): RolesDto[] {
    var roleDtos: RolesDto[] = [];
    var rolenames = item['Access Group Roles'].split(',');
    rolenames.forEach(function (name: string) {
      var roleDto = new RolesDto();
      roleDto.name = name;
      roleDto.createdBy = item['Created By'];
      roleDto.modifiedBy = item['Modified By'];
      roleDto.description = name;
      roleDtos.push(roleDto);
    });
    return roleDtos;
  }
  importExcelData(sarUsers: UserDto[]) {
    //call Import API
  }

  ExportExcel(grid: GridComponent) {
    grid.saveAsExcel();
  }

  addEditMMSUser(isNew: boolean, id: number, isActive: boolean, cai: string, name: string, email: string, businessUnitName: string, businessUnitId: number, roles: string[], groups: string[]) {
    this.router.navigate([`../${MMSUserRoutingConstants.MMSUserManagement}/${MMSUserRoutingConstants.MMSUserDetails}`],
      { state: { isNew: isNew, id: id, isActive: isActive, cai: cai, name: name, email: email, businessUnitName: businessUnitName, businessUnitId: businessUnitId, searchedText: this.searchedText }, relativeTo: this.route })
  }

  resetGrid() {
    this.searchedText = '';
    this.getGridData(this.checked);
  }
}
