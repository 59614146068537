import { BaseDto } from "./base.model";
import { MMSGroupDto } from "./mms-group.model";
import { DepartmentDto } from "./departments.model";
import { RolesDto } from "./roles.model";
import { AssetDto } from "./asset.model";
import { GroupsDto } from "./groups.model";
import { MmsUserDto } from "./mms-user.model";
import { MmsUserAssetGroupsDto } from "./mms-user-asset-group.model";
import { DOALevelDto } from "./doa-Level.model";

export class UserDto extends BaseDto {
    id!: number;
    name?: string;
    email?: string;
    externalId?: string;
    businessUnitId!: number;
    businessUnitName?: string;
    lastLogin?: Date;
    cai?: string;
    accessGroupIds: number[] = [];
    accessGroupsDto?: GroupsDto[];
    irisDOALevelId!: number;
    departmentId: number[] = [];
    rolesDto?: RolesDto[] | undefined;
    mmsUserAssetGroupsDtos?: MmsUserAssetGroupsDto[];
    departmentdto?: DepartmentDto[];
    mmsIrisGroupId?: number[];
    userAssetGroupsId?: number[];
    doaLevelDtos?: DOALevelDto;
    assetGroupDto?: AssetDto[];
    irisAccessgroupsDto?: GroupsDto[];
    rolesArr: string = '';
    accessGroupsArr: string = '';
}