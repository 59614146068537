<!-- header start -->
<div kendoDialogContainer></div>
<kendo-appbar positionMode="fixed" class="app-head">
  <kendo-progressbar *ngIf="running !== null" [label]="labelSettings" [progressCssStyle]="progressStyles"
    [value]="value"></kendo-progressbar>

  <kendo-appbar-section>
    <div class="logo">
      <img src="/assets/icons/CVX_Logo_Corp_Flat_Rev_RGB.png" alt="" />
    </div>
    <h1 class="title">MMS</h1>
  </kendo-appbar-section>

  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section class="actions">
    <kendo-button #anchor class="teamMate mr-20"
      style="width: auto; display: flex; align-items: center; cursor: default;">
      <div class="profile">
        <p>{{ profileShortName }}</p>
      </div>
      <span class="mate-info">
        <h2>
          Welcome, {{ profile?.givenName }} {{ profile?.surname }}
          <!-- <span class="ml-5 k-icon k-i-logout k-i-sign-out" (click)="logout()" *ngIf="showHeaderPopup">
          </span> 
          <span class="ml-5 k-icon k-i-arrow-60-down" (click)="onToggle()" *ngIf="!showHeaderPopup">
          </span>-->
          <span class="ml-5 k-icon k-i-arrow-60-down" (click)="onToggle()">
          </span>
        </h2>
      </span>
    </kendo-button>
  </kendo-appbar-section>
</kendo-appbar>

<div id="headerPopup">
  <kendo-popup class="wrapper" [anchor]="anchor.element" (anchorViewportLeave)="show = false" *ngIf="show">
    <div class="content">
      <span class="userDetailsRow">
        <p>
          <span id="welcomeTXT">Welcome back!</span>
          <span id="userMailId">{{ userEmailId }}</span>
        </p>
      </span>
      <hr class="separator" />
      <span class="userDetailsRow">
        <p (click)="navMyProfile()"> My Profile </p>
      </span>
      <hr class="separator" />
      <span class="userDetailsRow" (click)="logout()">
        <p> Log Out </p>
      </span>
    </div>
  </kendo-popup>

</div>