import { Injectable } from '@angular/core';
import { SupplierDto } from '../../models/supplier.model';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {


  SupplierList: SupplierDto[] = [
    {
      id: 1,
      supplierName: 'Supplier 1'
    },
    {
      id: 2,
      supplierName: 'Supplier 2'
    },
    {
      id: 3,
      supplierName: 'Supplier 3'
    }
  ]
  constructor(private utilityService: HttpUtilityService) { }

  getSupplierMockList(): SupplierDto[] {
    return this.SupplierList;
  }
}
