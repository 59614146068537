import { Injectable } from '@angular/core';
import { ManageNotificationDto } from '../../models/manage-notification.model';
import { Observable } from 'rxjs';
import { ManageNotificationsConst } from 'src/app/core/constants/MMS_User/manage-notification/manage-notification.constants';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageNotificationService {

  constructor(private http: HttpClient) {
  }

  getManageNotifocationItemId(id: number): Observable<ManageNotificationDto> {
    return this.http.get<ManageNotificationDto>(environment.IRIS.apiPath + ManageNotificationsConst.apiPath + `/${id}`);
  }

  updateManageNotification(userId: number, mn: ManageNotificationDto): Observable<ManageNotificationDto> {
    return this.http.put<ManageNotificationDto>(environment.IRIS.apiPath + ManageNotificationsConst.apiPath + `/${userId}`, mn);
  }

}
