export const MMSRoutingConstants = {
    IRIS: 'https://iris-dev.azure.chevron.com/MMS_IRIS/MyIRIS',
    MMSDashboard: 'MMS',
    MMSUser: 'MMSUser',
    UserGuide: 'USER_GUIDE',
    HelpGettingStarted: 'HelpGettingStarted',
    HelpManageUser: 'HelpManageUser',
    profile: 'MMS_Profile/MyProfile',
    InvalidPermission: 'InvalidPermission',
    IRIS_InvalidAccess: "You don't have permissions to access IRIS Module"
}