import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { ManageNotificationService } from 'src/app/feature/mms-user/services/manage-notification/manage-notification.service';
import { ManageNotificationDto } from 'src/app/feature/mms-user/models/manage-notification.model';
import { ManageNotificationsConst } from '../../constants/MMS_User/manage-notification/manage-notification.constants';
import { MMSUserService } from 'src/app/feature/mms-user/services/mms-user/mms-user.service';
import { UserDto } from 'src/app/feature/mms-user/models/user.model';
import { SharedConst } from '../../constants/shared.constants';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  item?: ManageNotificationDto;
  emailId: string = '';
  secondaryEmailId: string = '';
  emailToggle: boolean = true;
  delegateUseId: number = 0;
  delegationToggle: boolean = false;
  isValidEmailId: boolean = true;
  resetItem?: ManageNotificationDto;
  delegatedList: UserDto[] = [];
  chevronDomain: string = '@chevron.com';
  userId: number = 0;
  constructor(private commonService: CommonService, private router: Router, private route: ActivatedRoute, private manageNotificationsService: ManageNotificationService,
    private userService: MMSUserService) { }

  ngOnInit(): void {
    this.getManageNotificationItem();
    this.getDelegatedUsers();
  }
  getDelegatedUsers() {
    this.userService.getMMSUserList().subscribe((users: UserDto[]) => {
      this.delegatedList = users;
    })
  }

  getManageNotificationItem() {
    this.userService.getUserByCAI(this.commonService.getUserCAI()).subscribe(
      (user: UserDto) => {
        this.userId = user.id;
        this.manageNotificationsService.getManageNotifocationItemId(user.id).subscribe((item: ManageNotificationDto) => {
          this.item = item;
          this.resetItem = Object.assign([], item);
          if (this.item) {
            this.getDBData(this.item);
          }
        })
      });
  }

  getDBData(item: ManageNotificationDto) {
    this.emailId = item.emailId ? item.emailId.split('@')[0] : '';
    this.secondaryEmailId = item.secondaryEmailId ? item.secondaryEmailId.split('@')[0] : '';
    this.emailToggle = item.receivEmail!;
    this.delegateUseId = item.delegateUseId ? item.delegateUseId : 0;
    this.delegationToggle = item.isDelegationActive ? item.isDelegationActive : false;
  }
  delegatedSelected(value: UserDto) {

  }
  action(actionName: string) {
    if (actionName === 'reset') {
      this.isValidEmailId = true;
      if (this.resetItem) {
        this.getDBData(this.resetItem);
      }
    }
    else if (actionName === 'save') {
      if (this.emailId === '') {
        this.isValidEmailId = false;
        this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center')
      }
      else {
        if (this.item) {
          this.item!.emailId = this.emailId + this.chevronDomain;
          this.item!.secondaryEmailId = this.secondaryEmailId !== '' ? this.secondaryEmailId + this.chevronDomain : '';
          this.item!.receivEmail = this.emailToggle;
          this.item!.delegateUseId = this.delegationToggle === false ? 0 : this.delegateUseId;
          this.item!.isDelegationActive = this.delegationToggle;
          this.item!.modifiedDateTime = new Date;
          this.item!.modifiedBy = this.commonService.getUserEmailId();
        }
        this.manageNotificationsService.updateManageNotification(this.userId, this.item!).subscribe((data: any) => {
          this.commonService.showNotificationMessage(ManageNotificationsConst.savedMyProfileMsg, 'success', 'center')
        })
      }
    }
  }
}
