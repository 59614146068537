import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { AssetDto } from '../../models/asset.model';
import { AssetConst } from 'src/app/core/constants/MMS_User/asset/asset.constants';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private httpUtilityService: HttpUtilityService) { }

  getAssetsListByBUId(buId: number): Observable<AssetDto[]> {
    return this.httpUtilityService.getAll(AssetConst.apiPath + AssetConst.getAssetByBuId, { params: { buId: buId } })
  }
}
