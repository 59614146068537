<div class="iris-locationDetail-container j-c-s main-container-padding">
    <h1 class="mb-20 setup-heading">{{heading}}</h1>
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">Cancel</button>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">Save</button>
    </div>
    <section class="mt-20">
        <section class="toggle-active-inactive" *ngIf="isNew">
            <div class="d-flex w-30 mt-10 a-center">
                <kendo-label class="w-40">Internal User</kendo-label>
                <kendo-switch [disabled]="true" [(ngModel)]="isExternalUser" class="mt-10 w-10"
                    (valueChange)="onUserSwichChange($event)">
                </kendo-switch>
                <kendo-label class="text-align-right w-40">External User</kendo-label>
            </div>
        </section>
        <div *ngIf="!isNew">
            <kendo-label>Is Active</kendo-label>
            <br />
            <kendo-switch [(ngModel)]="isActive" (click)="onActiveChange()" class="mt-10 w-10">
            </kendo-switch>
        </div>
        <!-- Internal User -->
        <section *ngIf="!isExternalUser">
            <form [formGroup]="mmsUserForm">
                <div *ngIf="isNew" class="mt-20 w-100 d-flex">
                    <div class="w-15">
                        <kendo-label>CAI <span style="color:red"> *</span></kendo-label>
                        <br />
                        <kendo-formerror *ngIf="hasCAIError">{{caiError}}</kendo-formerror>
                    </div>
                    <div class="w-35"><kendo-textbox #fcai formControlName="fcai"
                            (blur)="handleBlur();"></kendo-textbox>
                    </div>
                </div>
                <div class="mt-20 w-100 d-flex">
                    <div class="w-15">
                        <kendo-label>Business Unit <span style="color:red"> *</span></kendo-label>
                        <br />
                        <kendo-formerror *ngIf="hasBUError">{{buError}}</kendo-formerror>
                    </div>
                    <div class="w-35">
                        <kendo-dropdownlist [data]="businessUnitList" textField="name" valueField="name" #businessUnits
                            formControlName="businessUnitName" (selectionChange)="selectBusinessUnit($event)"
                            [defaultItem]="{ name: '-- Select Business Unit --'}"
                            [valuePrimitive]="true"></kendo-dropdownlist>
                    </div>
                </div>
                <div class="mt-20 w-100 d-flex">
                    <div class="w-15">
                        <kendo-label>Access Group Name <span style="color:red"> *</span></kendo-label>
                        <br />
                        <kendo-formerror *ngIf="hasAccessGroupError">{{accessGroupError}}</kendo-formerror>
                    </div>
                    <div class="w-35"><kendo-multiselect [data]="groupsList" textField="name" valueField="id"
                            [(ngModel)]="selectedAccessGroupNames" #accessGroups [ngModelOptions]="{standalone: true}"
                            (valueChange)="selectAccessGroup($event)"
                            placeholder="-- Select Access Group Name --"></kendo-multiselect>
                    </div>
                </div>
                <div class="mt-20 w-50" *ngIf="!isNew">
                    <fieldset class="mb-20  fieldset">
                        <legend class="font-size-xs">User Roles</legend>
                        <kendo-label>{{userRolesStr}}</kendo-label>
                    </fieldset>
                </div>
                <div class="mt-20 w-50">
                    <fieldset class="mb-20  fieldset">
                        <legend class="font-size-xs">MMS Module Selection</legend>
                        <div class="mt-20 w-100 d-flex">
                            <h4 class="w-15">Module</h4>
                            <h4 class="w-25">Access Group</h4>
                            <h4 class="w-25"></h4>
                        </div>
                        <div class="mt-20 w-100 d-flex">
                            <div class="w-15">
                                <kendo-label>SAR</kendo-label>
                                <br />
                            </div>
                            <div class="w-35"><kendo-dropdownlist aria-placeholder="-- Please select user roles(s)--"
                                    [disabled]="true"></kendo-dropdownlist>
                            </div>
                        </div>
                        <div class="mt-20 w-100 d-flex">
                            <div class="w-15">
                                <kendo-label>IRIS</kendo-label>
                                <br />
                            </div>
                            <div class="w-35"><kendo-multiselect #iris_id [(ngModel)]="selected_iris_groups"
                                    [ngModelOptions]="{standalone: true}" [data]="iris_user_groups" textField="name"
                                    valueField="id" placeholder="-- Please select user role(s) --"
                                    (valueChange)="selectIRISUserGroups($event)">
                                </kendo-multiselect></div>
                            <div class="w-15"></div>
                            <div class="w-35" *ngIf="false"><kendo-dropdownlist></kendo-dropdownlist>
                            </div>
                        </div>
                    </fieldset>

                </div>
                <!--IRIS-->
                <div class="mt-20 w-35" *ngIf="this.selected_iris_groups.length>0">
                    <fieldset class="mb-20  fieldset">
                        <legend class="font-size-xs">IRIS</legend>
                        <!-- <div class="mt-20" *ngIf="!isNew">
                            <kendo-label>JDE ONLY DOA</kendo-label>
                            <br />
                            <kendo-switch [(ngModel)]="isJDEOnlyDOAChange" class="mt-10 w-10"
                                (valueChange)="onJDEOnlyDOAChange($event)" [ngModelOptions]="{standalone: true}">
                            </kendo-switch>
                        </div> -->
                        <!-- <div class="note  mx-5 d-inline-block">
                            Note: Please select one Business Unit to get IRIS DOA Levels and User Asset Group(s).
                        </div> -->
                        <div>
                            <kendo-label>IRIS DOA Level
                            </kendo-label>
                            <br />
                            <kendo-dropdownlist [data]="doaLevelList" textField="displayName" valueField="displayName"
                                [defaultItem]="{displayName:'-- Select DOA Level --'}" formControlName="doaLevel"
                                (selectionChange)="selectDOALevel($event)" [valuePrimitive]="true"></kendo-dropdownlist>
                        </div>
                        <div class="mt-20">
                            <kendo-label>User Asset Group(s)</kendo-label>
                            <br />
                            <kendo-multiselect [filterable]="true" [(ngModel)]="selectedAssets"
                                [ngModelOptions]="{standalone: true}" [data]="assets" textField="label" valueField="id"
                                placeholder="-- Please Select Assets(s) --" (valueChange)="selectAssets($event)">
                            </kendo-multiselect>
                        </div>
                        <div class="mt-20">
                            <kendo-label>User Department(s)</kendo-label>
                            <br />
                            <kendo-multiselect [filterable]="true" [(ngModel)]="selectedDepartments"
                                [ngModelOptions]="{standalone: true}" [data]="departments" textField="label"
                                valueField="id" placeholder="-- Please Select Department(s) --"
                                (valueChange)="selectDepartements($event)">
                            </kendo-multiselect>
                        </div>
                    </fieldset>

                </div>
            </form>
        </section>
        <!--External User-->
        <section *ngIf="isExternalUser">
            <div class="mt-20 w-100 d-flex">
                <div class="w-15">
                    <kendo-label>Email Address <span style="color:red"> *</span></kendo-label>
                    <br />
                    <kendo-formerror *ngIf="requiredEmail">Please enter a valid email address</kendo-formerror>
                </div>
                <div class="w-25"><kendo-textbox [required]="true" [(ngModel)]="emailAddress"></kendo-textbox>
                </div>
            </div>
            <div class="mt-20 w-100 d-flex">
                <div class="w-15">
                    <kendo-label>Suppliers</kendo-label>
                    <br />
                </div>
                <div class="w-25">
                    <kendo-multiselect [(ngModel)]="selectedSuppliers" [data]="suppliers" textField="supplierName"
                        valueField="id" placeholder="-- Please Select Supplier(s) --"
                        (valueChange)="selectSuppliers($event)">
                    </kendo-multiselect>
                </div>
            </div>
        </section>
    </section>
</div>