import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { BusinessUnitDto } from '../../models/bussinessunits.model';
import { BusinessUnitConstants } from 'src/app/core/constants/MMS_User/business-Unit/business-unit.constants';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    }

  //   to get business units records
    getAllBUNames(): Observable<BusinessUnitDto[]> {
        return this.httpUtilityService.getAll(BusinessUnitConstants.getBusinessUnit);
    }
}