<div class="logout-container">
  <kendo-appbar class="app-head">
    <kendo-appbar-section>
      <div class="logo">
        <img src="./../assets/icons/CVX_Logo_Corp_Flat_Rev_RGB.png" alt="">
      </div>
    </kendo-appbar-section>
  </kendo-appbar>
  <br /><br />
    <img src="../../../../assets/icons/ChevronLogo-logout.png" alt="">  
  <div class="text">
    <h2>You have successfully signed out of your application.</h2>
    <p class="navigation-link">
      You may close this window, or
      <span (click)="returnToApplication()">click here to return to your application.</span>
    </p>
  </div>
</div>
