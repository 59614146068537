<!-- <h2 class="heading fixed-top"></h2> -->
<section class="userguide-content-box">
    <app-user-guide-header></app-user-guide-header>
    <div class="UsageGuideHeader">
        System Usage Guide
    </div>
    <div class="ContentParentContainer">

        <!-- Summary Container -->
        <app-system-usage-guide (HelpGettingStarted)="navToFragment($event)"></app-system-usage-guide>

        <!-- Content Container -->
        <div class="ContentContainer">
            <div class="Header">Getting Started with MMS</div>
            <div class="SubHeader" #aboutMMS>About MMS Portal</div>
            <p>The Material Management System (MMS) portal is an application that helps the user to access different
                available module(s):</p>
            <ul>
                <li><b>IRIS:</b> Investment Recovery and Inventory Solution</li>
            </ul>
            <div>
                <img src="./../../../../../assets/Images/MMS_Portal.PNG" alt="">
            </div>
            <div class="SubHeader" #accessingTool>Accessing the Tool</div>
            <p>
                You can access the tool via the following URL:<br>
                <a href="/MMS">{{mms_url}}</a> <br>
                The system needs Single Sign On (SSO), providing you’re using Chevron device.<br>
                If you require a privileged level of access, please contact the system administrator.<br>
            </p>
            <br>            
        </div>

        <!-- Contact Parent Container -->
        <app-contact-support></app-contact-support>
    </div>
</section>