import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-help-getting-started',
  templateUrl: './help-getting-started.component.html',
  styleUrls: ['./help-getting-started.component.scss']
})
export class HelpGettingStartedComponent {
  mms_url: string = environment.azureAd.redirectUri + '/MMS';
  fragment: string = '';
  @ViewChild('aboutMMS') aboutMMS!: ElementRef;
  @ViewChild('accessingTool') accessingTool!: ElementRef;
  constructor(private router: Router, private commonService: CommonService) {
    let state: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(state)) {
      this.fragment = state['fragment'];
    }
  }
  ngAfterViewInit() {
    if (this.fragment !== '') {
      this.navToFragment(this.fragment);
      this.fragment = '';
    }
  }
  navToFragment(fragment: string) {
    switch (fragment) {
      case 'aboutMMS': {
        this.aboutMMS.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      }
      case 'accessingTool': {
        this.accessingTool.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      }
    }
  }

}
