<div class="TitleContainer">
    <div>
        <h1>Hello there, Welcome to MMS Support</h1>
        <h1>How can we help you today?</h1>
    </div>
    <div>
        <img src="./../../../../../assets/Images/Header.svg" alt="">
    </div>
</div>

<div class="card">
    <a (click)="navManageUsers()">
        <div class="LinkCard">
            <img src="./../../../../../assets/Images/Createirisrequest.svg" alt="">
            <h4>How to manage Users</h4>
        </div>
    </a>
</div>
