import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MMSRoutingConstants } from 'src/app/core/constants/MMS-routing-constants/mms-routing-constants';

@Component({
  selector: 'app-system-usage-guide',
  templateUrl: './system-usage-guide.component.html',
  styleUrls: ['./system-usage-guide.component.scss']
})
export class SystemUsageGuideComponent {
  @Output() HelpGettingStarted = new EventEmitter<string>();
  @Output() ManageUsers = new EventEmitter<string>();
  currentPath: string = window.location.href;
  constructor(private router: Router, private route: ActivatedRoute) {

  }

  nav_ManageMMSUSers(fragment: string) {
    let isCurrentPage = this.currentPath.includes(MMSRoutingConstants.HelpManageUser);
    if (isCurrentPage) {
      this.ManageUsers.emit(fragment);
    }
    else {
      this.router.navigate(['../' + MMSRoutingConstants.HelpManageUser], { relativeTo: this.route, state: { fragment: fragment } });
    }
  }
  nav_HelpGettingStarted(fragment: string) {
    let isCurrentPage = this.currentPath.includes(MMSRoutingConstants.HelpGettingStarted);
    if (isCurrentPage) {
      this.HelpGettingStarted.emit(fragment);
    }
    else {
      this.router.navigate(['../' + MMSRoutingConstants.HelpGettingStarted], { relativeTo: this.route, state: { fragment: fragment } })
    }
  }
}
