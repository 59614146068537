import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { DepartmentDto } from '../../models/departments.model';
import { DepartmentsConstants } from 'src/app/core/constants/MMS_User/department/department.constants';

@Injectable({
  providedIn: 'root',
})
export class DepartmetsService {
  constructor(
    public http: HttpClient,
    public httpUtilityService: HttpUtilityService
  ) { }

  // to get the departments list
  getDepartmentsRecord(): Observable<DepartmentDto[]> {
    return this.httpUtilityService.get(`${DepartmentsConstants.getDepartment}`);
  }
}
