import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { DOALevelDto } from '../../models/doa-Level.model';
import { Observable } from 'rxjs';
import { DOALevelsConst } from 'src/app/core/constants/MMS_User/doa-levels/doa-levels.constants';

@Injectable({
  providedIn: 'root'
})
export class DOALevelsService {

  constructor(private utilityService: HttpUtilityService) {
  }
  getDOALevelList(): Observable<DOALevelDto[]> {
    return this.utilityService.get(DOALevelsConst.ApiPath);
  }

  getDOALevelsbyBuId(buId: number): Observable<DOALevelDto[]> {
    return this.utilityService.get(DOALevelsConst.ApiPath + DOALevelsConst.getDoaLevelByBuId, { params: { id: buId } });
  }
}
