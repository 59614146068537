import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RootComponent } from './core/components/root/root.component';
import { FeaturesModule } from './modules/features.module';
import { CoreModule } from './modules/core.module';
import { MsalGuard, MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { InvalidPermissionsComponent } from './core/components/invalid-permissions/invalid-permissions.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/interceptors/httpconfig.interceptor';
import { RoleService } from './feature/mms-user/services/role/role.service';
import { KendoModule } from './modules/kendo.module';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { LogoutComponent } from './core/components/logout/logout/logout.component';
import { ProfileComponent } from './core/components/profile/profile.component';

@NgModule({
  declarations: [
    RootComponent,
    InvalidPermissionsComponent,
    LogoutComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KendoModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    FeaturesModule,
    HttpClientModule,    
    LabelModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    RoleService,
    MsalGuard,
  ],
  bootstrap: [RootComponent, MsalRedirectComponent]
})
export class AppModule { }
