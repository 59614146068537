import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { GroupsDto } from '../../models/groups.model';
import { GroupsConst } from 'src/app/core/constants/MMS_User/group/group.constants';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(public httpUtilityService: HttpUtilityService) { }

  // to get the Access Group list
  getAccessGroupList(): Observable<GroupsDto[]> {
    return this.httpUtilityService.get(`${GroupsConst.apiPath}`);
  }

  getIRISAccessGroupList(): Observable<GroupsDto[]> {
    return this.httpUtilityService.get(`${GroupsConst.apiPath + GroupsConst.getMmsIrisGroups}`);
  }
}
