import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { UserDto } from '../../models/user.model';
import { MMSUsersConstant } from 'src/app/core/constants/MMS_User/user-manager/mms-users.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MMSUserService {
    constructor(private httpUtilityService: HttpUtilityService) { }
    getMMSUserList(): Observable<UserDto[]> {
        return this.httpUtilityService.getAll(MMSUsersConstant.apiPath);
    }

    addUser(user: UserDto): Observable<UserDto> {
        return this.httpUtilityService.post(MMSUsersConstant.apiPath, user)
    }

    editUser(id: number, user: UserDto): Observable<UserDto> {
        return this.httpUtilityService.put(MMSUsersConstant.apiPath + '/' + id, user)
    }

    getUserById(id: number): Observable<UserDto> {
        return this.httpUtilityService.get(MMSUsersConstant.apiPath + '/' + id)
    }

    getUserByCAI(cai: string): Observable<UserDto> {
        return this.httpUtilityService.get(MMSUsersConstant.apiPath + MMSUsersConstant.getByCai + '/' + cai)
    }

    checkEndoserCount(): Observable<number> {
        return this.httpUtilityService.get(MMSUsersConstant.apiPath + MMSUsersConstant.checkEndoserCount)
    }

}
