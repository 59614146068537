import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private commonService: CommonService, private router: Router, private route: ActivatedRoute, private msalService: MsalService) {
  }

  ngOnInit(): void {
  }

  returnToApplication() {
    localStorage.removeItem('loggedOut');
    this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
  }
}
