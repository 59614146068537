export const RoleConstant = {
  Role: 'api/Role',
  Admin: 'Admin',
  User: 'User',
  IRIS_MaterialCoordinator: 'IRISMaterialCoordinator',
  IRIS_Requestor: 'IRISRequestor',
  IRIS_Reviewer: 'IRISReviewer',
  IRIS_Finance: 'IRISFinance',
  IRIS_Commercial: 'IRISCommercial',
  IRIS_Endorser: 'IRISEndorser',
  IRIS_DOA: 'IRISDOA'
};
