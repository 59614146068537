import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-invalid-permissions',
  templateUrl: './invalid-permissions.component.html',
  styleUrls: ['./invalid-permissions.component.scss']
})
export class InvalidPermissionsComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void { }
  navigate() {
    window.location.href = environment.azureAd.redirectUri;
  }
}
