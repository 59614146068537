import { NgModule } from '@angular/core';
import { UserGuideHeaderComponent } from '../components/sub-components/user-guide-header/user-guide-header.component';
import { HelpGettingStartedComponent } from '../components/help-getting-started/help-getting-started.component';
import { ContactSupportComponent } from '../components/sub-components/contact-support/contact-support.component';
import { SystemUsageGuideComponent } from '../components/sub-components/system-usage-guide/system-usage-guide.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { KendoModule } from 'src/app/modules/kendo.module';
import { ManageMmsUsersComponent } from '../components/manage-mms-users/manage-mms-users.component';

@NgModule({
    declarations: [
        ContactSupportComponent,
        SystemUsageGuideComponent,
        UserGuideHeaderComponent,
        HelpGettingStartedComponent,
        ManageMmsUsersComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        KendoModule,
    ],
    exports: [
        HelpGettingStartedComponent,
        ManageMmsUsersComponent
    ]
})
export class UserGuideModule { }