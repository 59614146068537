import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { ProfileType } from '../../model/graph.models';
import { CommonService } from '../../services/common.service';
import { GlobalAuthService } from '../../services/global-auth.service';
import { LoadingService } from '../../services/loading-progress-bar.service';
import { MMSUserService } from 'src/app/feature/mms-user/services/mms-user/mms-user.service';
import { UserDto } from 'src/app/feature/mms-user/models/user.model';
import { RolesDto } from 'src/app/feature/mms-user/models/roles.model';
import { RoleConstant } from '../../constants/role.constants';
import { MMSRoutingConstants } from '../../constants/MMS-routing-constants/mms-routing-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('anchor', { read: ElementRef }) public anchor: | ElementRef | undefined;
  @ViewChild('popup', { read: ElementRef }) public popup: | ElementRef | undefined;
  @Output() loadContent = new EventEmitter<boolean>();
  @Output() isMMSUser = new EventEmitter<boolean>();
  @Output() isAdmin = new EventEmitter<boolean>();
  @Output() logOut = new EventEmitter<boolean>();
  profile!: ProfileType | null;
  setLoginDisplay!: boolean | null;
  userRole: string | undefined;
  userEmailId: string = '';
  showHeaderPopup: boolean = false;
  // userBUName: string | undefined;
  public value = 0;
  public running!: any;
  public profileShortName: string = '';
  public show = false;
  public labelSettings: LabelSettings = { visible: false };
  public progressStyles: { [key: string]: string } = {
    color: 'white',
    background: '#596EE4',
  };

  constructor(
    private globalAuthService: GlobalAuthService,
    public commonService: CommonService,
    public loadingService: LoadingService,
    public router: Router,
    private route: ActivatedRoute,
    private mmsUserService: MMSUserService
  ) {
    this.loadingService.isLoading$.subscribe((value) => {
      if (value) {
        this.startProgress();
      } else {
        this.resetProgress();
      }
    });
  }

  ngOnInit(): void {
    this.globalAuthService.profile.subscribe((profile) => {
      this.profile = profile;
      this.loggedInUserShortName();
      this.userEmailId = this.profile?.userPrincipalName!;
      sessionStorage.setItem('userEmailID', JSON.stringify(profile?.userPrincipalName));
      sessionStorage.setItem('cai', JSON.stringify(profile?.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11));
      sessionStorage.setItem('firstName', JSON.stringify(profile?.givenName));
      sessionStorage.setItem('lastName', JSON.stringify(profile?.surname));
      sessionStorage.setItem('CAI', profile?.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11!);
      this.loadContent.emit(true);
      this.mmsUserService.getUserByCAI(profile?.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11!).subscribe(
        (user: UserDto) => {
          if (user.isActive === null || user.isActive === false) {
            this.isMMSUser.emit(false);
            this.isAdmin.emit(false);
          }
          else if (user.rolesDto && user.rolesDto.length > 0) {
            let userRoles: string[] = user.rolesDto.map((role: RolesDto) => { return role.name! });
            sessionStorage.setItem('roles', userRoles.join(','));
            this.checkUser(userRoles);
            this.checkAdmin(userRoles);
          }
          else {
            sessionStorage.setItem('roles', '');
            this.isMMSUser.emit(false);
            this.isAdmin.emit(false);
          }
        }, (error) => {
          this.isMMSUser.emit(false);
          this.isAdmin.emit(false);
        })
    });
    this.globalAuthService.loginDisplay.subscribe((setLoginDisplay) => (this.setLoginDisplay = setLoginDisplay));
    //this.showHideHeaderPopup();
    this.showHeaderPopup = true;
  }

  checkUser(roles: string[]) {
    let user_roles: string[] = roles.filter((role: string) => role === RoleConstant.User);
    if (user_roles.length > 0)
      this.isMMSUser.emit(true);
    else
      this.isMMSUser.emit(false);
  }
  checkAdmin(roles: string[]) {
    let user_roles: string[] = roles.filter((role: string) => role === RoleConstant.Admin);
    if (user_roles.length > 0)
      this.isAdmin.emit(true);
    else
      this.isAdmin.emit(false);
  }
  loggedInUserShortName() {
    if (this.profile?.givenName && this.profile?.surname) {
      const fName = this.profile?.givenName.toUpperCase().toString().charAt(0);
      const lName = this.profile?.surname.toUpperCase().toString().charAt(0);
      this.profileShortName = `${fName}${lName}`;
    }
  }

  showHideHeaderPopup() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/MMS': {
            this.showHeaderPopup = true;
            break;
          }
          case '/MMSUser': {
            this.showHeaderPopup = true;
            break;
          }
          case '/MMSUser/MMSUserDetails': {
            this.showHeaderPopup = true;
            break;
          }
          default: {
            this.showHeaderPopup = false;
            break;
          }
        }
      }
    });
  }

  logout(): void {
    this.commonService.clearSession();
    localStorage.clear();
    this.logOut.emit(true);
  }

  public onToggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
  }

  reload() {
    window.location.reload();
  }

  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.onToggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.onToggle(false);
    }
  }
  private contains(target: EventTarget | null): boolean {
    return (
      this.anchor!.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  public startProgress(): void {
    this.running = setInterval(() => {
      if (this.loadingService.isLoading$) {
        this.value++;
        if (this.value == 99) {
          this.value = 0;
        }
      } else {
        this.resetProgress();
      }
    }, 500);
  }

  public stopProgress(): void {
    if (this.running) {
      clearInterval(this.running);
      this.running = null;
    }
  }

  public resetProgress(): void {
    this.value = 0;
    this.stopProgress();
  }

  navMyProfile() {
    this.router.navigate(['./' + MMSRoutingConstants.profile])
  }
}
