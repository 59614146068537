import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
@Component({
  selector: 'app-manage-mms-users',
  templateUrl: './manage-mms-users.component.html',
  styleUrls: ['./manage-mms-users.component.scss']
})
export class ManageMmsUsersComponent implements AfterViewInit {
  fragment: string = '';
  @ViewChild('mmsUser') mmsUser!: ElementRef;
  @ViewChild('newUser') newUser!: ElementRef;
  @ViewChild('irisFields') irisFields!: ElementRef;
  constructor(private router: Router, private commonService: CommonService) {
    let state: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(state)) {
      this.fragment = state['fragment'];
    }
  }
  ngAfterViewInit() {
    if (this.fragment !== '') {
      this.navToFragment(this.fragment);
      this.fragment = '';
    }
  }
  navToFragment(fragment: string) {
    switch (fragment) {
      case 'mmsUser': {
        this.mmsUser.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      }
      case 'newUser': {
        this.newUser.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      }
      case 'irisFields': {
        this.irisFields.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      }
    }
  }
}
