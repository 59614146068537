import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { Observable, of } from 'rxjs';
import { RolesDto } from '../../models/roles.model';
import { UserAndRoleConst } from 'src/app/core/constants/userandrole.const';
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
  }
  getRole() {
    return this.httpUtilityService.getAll(RoleConstant.Role);
  }
  saveRole(data: RolesDto) {
    return this.httpUtilityService.post(RoleConstant.Role, data);
  }
  updateRole(data: RolesDto) {
    return this.httpUtilityService.put(RoleConstant.Role, data.id, data);
  }
  getRoleByEmail<T>(email: string): Observable<T> {
    return this.httpUtilityService.getAll(
      UserAndRoleConst.userAPI + UserAndRoleConst.getAllUserDetailsByEmail + '/' + email.toString());
  }
}
