export const MMSUsersConstant = {
    apiPath: 'api/User',
    getByCai: '/GetByCai',
    caiError: 'Please enter a valid CAI.',
    caiLengthError: 'CAI should be of 4 characters length, Please enter valid CAI.',
    invalidCAIError: 'CAI entered is invalid, Please enter valid CAI.',
    successfullAddMsg: 'User has been successfully added',
    successfullEditMsg: 'User has been updated successfully',
    existingUserMsg: 'User already exists.',
    checkEndoserCount: '/CheckEndoserCount',
    limitEndorsersMsg: 'Adding new Endorser is not allowed! GOMBU has already two existing endorsers in MMS.',
    inactiveEndorserMsg: 'Please remove MMS_IRIS_Endorser Access Group before enabling this user. Maximum active IRIS Endorsers is equal to 2 in GOMBU. '
}