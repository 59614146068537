import { NgModule } from '@angular/core';
import { MMSModule } from '../feature/mms/module/mms.module';
import { MMSUserModule } from '../feature/mms-user/modules/mmsuser.module';
import { UserGuideModule } from '../feature/user-guide/module/user-guide.module';

@NgModule({
  declarations: [],
  imports: [MMSModule, MMSUserModule, UserGuideModule],
  exports: [MMSModule, MMSUserModule, UserGuideModule],
  providers: [],
})
export class FeaturesModule { }
