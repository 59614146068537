<div class="asset-location-container j-c-s m-container">
    <h1 class="mb-20 setup-heading">MMS User</h1>
    <div class="mr-20 float-right">
        <!-- <button kendoButton class="border-button ml-20 f-size-150" [disabled]="true" (click)="ImportExcel()">
            Import
        </button> -->
        <button kendoButton class="border-button ml-20 f-size-150" (click)="ExportExcel(grid)">
            Export
        </button>
        <button kendoButton class="border-button ml-20 f-size-150"
            (click)="addEditMMSUser(true,0, true, '', '', '', '', 0, [], [])">
            Add new MMS User
        </button>
    </div>

    <!-- Filter actions -->
    <section class="manage-contentbox">
        <section class="mt-20 d-flex">
            <div class="mt-20 pr-20 d-flex w-55">
                <kendo-textbox [(ngModel)]="searchedText" placeholder="Search by CAI or username"
                    (keydown.enter)="getGridData(checked)"></kendo-textbox>
                <button kendoButton class="primary-button ml-20 f-size-150" (click)="getGridData(checked)">
                    Search
                </button>
                <button kendoButton class="border-button ml-20 f-size-150" (click)="resetGrid()">
                    Reset
                </button>
            </div>
        </section>

        <!-- toggle active/inactive MMS User-->
        <section class="toggle-active-inactive">
            <div class="d-flex j-c-s w-45 mt-10 a-center">
                <p class="mt-10">Show Inactive MMS User</p>
                <kendo-switch [(ngModel)]="checked" class="mt-10 mr-30" (valueChange)="getGridData($event)">
                </kendo-switch>
            </div>
        </section>

        <!-- Table data -->
        <section class="mt-15">
            <kendo-grid [kendoGridBinding]="gridData" [resizable]="true" [pageSize]="10" #grid="kendoGrid"
                [pageable]="true" [sortable]="true">
                <!-- Excel -->
                <kendo-grid-excel [fetchData]="commonService.exportGridData(gridData, '', 'asc')"
                    [fileName]="fileName">
                    <kendo-excelexport-column field="cai" title="CAI">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="name" title="Name">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="businessUnitName" title="Business Unit">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="rolesArr" title="Access Group Roles">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="accessGroupsArr" title="Group Names">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="isActive" title="Is Active">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="createdBy" title="Created By">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="modifiedBy" title="Modified By">
                    </kendo-excelexport-column>
                </kendo-grid-excel>
                <kendo-grid-column field="cai" title="CAI" [width]="40">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a href="javascriipt:void();"
                            (click)="addEditMMSUser(false,dataItem.id,dataItem.isActive,dataItem.cai,dataItem.name, dataItem.email,dataItem.businessUnitName, dataItem.businessUnitId, dataItem.roles, dataItem.groups )">{{dataItem.cai}}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="name" title="Name" [width]="40">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a href="javascriipt:void();"
                            (click)="addEditMMSUser(false,dataItem.id,dataItem.isActive,dataItem.cai,dataItem.name, dataItem.email,dataItem.businessUnitName, dataItem.businessUnitId, dataItem.roles, dataItem.groups )">{{dataItem.name}}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="businessUnitName" title="Business Unit" [width]="60"></kendo-grid-column>
                <kendo-grid-column title="User Roles" [width]="60">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div>{{getRoleNames(dataItem.rolesDto,dataItem)}}</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="groups" title="User Groups" [width]="60">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div>{{getGroupNames(dataItem.accessGroupsDto,dataItem)}}</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="isActive" title="Is Active" [width]="40">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.isActive">
                            <span class="yesValue"> Yes</span>
                        </span>
                        <span *ngIf=" !dataItem.isActive">
                            <span class="noValue"> No</span>
                        </span>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>

            <!-- total data count -->
            <p class="grid-length mt-20">{{ gridData.length }} records</p>
        </section>
    </section>
</div>