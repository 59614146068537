<div>
  <app-header *ngIf="!loggedOut && isAuthenticated && is_User " (loadContent)="loadApplication($event)"
    (isMMSUser)="isMMSUSer($event)" (isAdmin)="isAdmin($event)" (logOut)="logout($event)"> </app-header>

  <!-- Body -->
  <section *ngIf="!loggedOut && isAuthenticated && is_User" class="m-100">
    <!-- left nav -->
    <nav class="sideNav">
      <div class="sItem" [ngStyle]="{ 'background-color': activeTab === 'MMS' ? '#D2E6FA' : '' }">
        <a (click)="activeTab = 'MMS'" routerLink="{{ MMSRoutingConstants.MMSDashboard }}">
          <div class="sideIcon">
            <img src="./../assets/icons/MMS.svg" alt="MMS" alt="MMS Dashboard" />
          </div>
          <p>MMS</p>
        </a>
      </div>
      <!-- <div class="sItem" [ngStyle]="{'background-color': activeTab === 'SAR' ? '#D2E6FA' : ''}">
        <a (click)="activeTab = 'MMS'">
          <div class="sideIcon">
            <img src="./../assets/icons/SAR_icon.PNG" alt="SAR" alt="SAR Dashboard" />
          </div>
          <p>SAR</p>
        </a>
      </div> -->
      <div class="sItem" [ngStyle]="{'background-color': activeTab === 'IRIS' ? '#D2E6FA' : ''}">
        <a (click)="activeTab = 'IRIS'; this.commonService.checkIrisAccess(); ">
          <div class="sideIcon">
            <img src="./../assets/icons/IRIS_icon.svg" alt="IRIS" alt="IRIS Dashboard" />
          </div>
          <p>IRIS</p>
        </a>
      </div>
      <div class="sidenav-bottom">
        <!-- <div *ngIf="isAdmin()"   -->
        <div class="sItem c-mb-30" [ngStyle]="{
            'background-color': activeTab === 'MMSUser' ? '#D2E6FA' : ''
          }" *ngIf="is_Admin">
          <a (click)="activeTab = 'MMSUser'" routerLink="{{ MMSRoutingConstants.MMSUser }}">
            <div class="sideIcon">
              <img src="./../assets/icons/UserIcon.png" width="36px" alt="MMSUser" />
            </div>
            <p class="GothamBook">MMSUser</p>
          </a>
        </div>
        <div class="sItem"
          [ngStyle]="{
            'background-color': activeTab === 'userguide' ? '#D2E6FA' : '' }"
        >
          <a (click)="activeTab = 'userguide'" routerLink="{{ MMSRoutingConstants.UserGuide }}">
            <div class="sideIcon">
              <img src="./../assets/icons/AllNCRs.svg" alt="User Guide" alt="User Guide"/>
            </div>
            <p>User Guide</p>
          </a>
        </div>
      </div>
    </nav>

    <!-- Right content -->
    <div class="main-content-box" *ngIf="isAuthenticated">
      <router-outlet></router-outlet>
    </div>
  </section>

  <app-invalid-permissions *ngIf="!loggedOut && (!is_User || !isAuthenticated)"></app-invalid-permissions>
  <app-logout *ngIf="loggedOut"></app-logout>
</div>