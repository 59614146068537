import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MMSRoutingConstants } from 'src/app/core/constants/MMS-routing-constants/mms-routing-constants';

@Component({
  selector: 'app-user-guide-header',
  templateUrl: './user-guide-header.component.html',
  styleUrls: ['./user-guide-header.component.scss']
})
export class UserGuideHeaderComponent {

  constructor(private router: Router, private route: ActivatedRoute) {

  }

  navManageUsers() {
    this.router.navigate(['../' + MMSRoutingConstants.HelpManageUser], { relativeTo: this.route })
  }
}
