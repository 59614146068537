import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MMSRoutingConstants } from '../../constants/MMS-routing-constants/mms-routing-constants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit, OnDestroy {
  MMSRoutingConstants = MMSRoutingConstants;
  activeTab: string = 'MMS';
  roles: string[] = [];
  is_User: boolean = true;
  is_Admin: boolean = true;
  isAuthenticated: boolean = true;
  loggedOut: boolean = false;
  IRIS_Path: string = environment.IRIS.urlPath;
  constructor(public commonService: CommonService) {
    if (!this.commonService.isNullOrEmptyOrUndefined(JSON.parse(localStorage.getItem('loggedOut')!))) {
      this.loggedOut = JSON.parse(localStorage.getItem('loggedOut')!);
    }
  }

  ngOnInit() {
    let splittedUrl = window.location.href.split('/');
    let url = splittedUrl[splittedUrl.length - 1];
    if ((url !== '' && url === 'MMS') || url === 'MMSUser') {
      this.activeTab = url;
    }
    else {
      this.activeTab = splittedUrl[splittedUrl.length - 2];
    }
  }
  ngOnDestroy(): void {
    localStorage.removeItem('loggedOut');
  }

  loadApplication(value: boolean) {
    this.isAuthenticated = value;
  }

  isMMSUSer(value: boolean) {
    this.is_User = value;
  }

  isAdmin(value: boolean) {
    this.is_Admin = value;
  }

  logout(value: boolean) {
    this.loggedOut = value;
    localStorage.setItem('loggedOut', JSON.stringify(value));
  }
}
