export const SharedConst = {
  empty: '',
  isActive: 'isActive',
  validationMessage: 'Please review the highlighted fields before proceeding',
  fileRequired: 'File is required',
  fileFormat: 'Please select the valid file format',
  importApiName: '/Import',
  dateFormatWithTime: 'dd-MMM-yyyy hh:mm a',
  dateFormat: 'dd-MMM-yyyy',
};
