import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KendoModule } from './kendo.module';
// import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { IconsModule } from '@progress/kendo-angular-icons';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from '../core/interceptors/msal.interceptor';
import { DialogUploadComponent } from '../core/components/dialog-upload/dialog-upload.component';
import { HttpConfigInterceptor } from '../core/interceptors/httpconfig.interceptor';
import { GraphService } from '../core/services/graph.service';
import { GlobalAuthService } from '../core/services/global-auth.service';
import { HttpUtilityService } from '../core/services/http.utility.service';
import { CommonService } from '../core/services/common.service';
import { LoadingService } from '../core/services/loading-progress-bar.service';
import { HeaderComponent } from '../core/components/header/header.component';

@NgModule({
  declarations: [
    HeaderComponent,
    DialogUploadComponent,
  ],
  imports: [MsalModule, KendoModule, FormsModule],
  exports: [
    HeaderComponent,
    DialogUploadComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GraphService,
    GlobalAuthService,
    HttpUtilityService,
    CommonService,
    LoadingService,
  ],
})
export class CoreModule { }
