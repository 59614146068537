<section class="userguide-content-box">
    <app-user-guide-header></app-user-guide-header>
    <div class="UsageGuideHeader">
        System Usage Guide
    </div>
    <div class="ContentParentContainer">

        <!-- Summary Container -->
        <app-system-usage-guide (ManageUsers)="navToFragment($event)"></app-system-usage-guide>

        <!-- Content Container -->
        <div class="ContentContainer">
            <div class="Header">Manage MMS Users</div>
            <p>To manage MMS users, you need to be an MMS Admin.</p>
            <div class="SubHeader" #mmsUser>MMS User</div>
            <p>The MMS User screen is where you will get a complete overview of all active and inactive users added to
                the system.</p>
            <div>
                <img src="./../../../../../assets/Images/users.PNG" alt="">
            </div>
            <p>
                To add new user, you can click on ‘Add new MMS User’ button.<br>
                To edit a specific user, you can click on the user’s CAI or Name.
            </p>
            <div class="SubHeader" #newUser>Add new MMS User</div>
            <p>
                To create a new MMS user, click on ‘Add new MMS User’ button and you will be redirected to a new screen.
            </p>
            <div>
                <img src="./../../../../../assets/Images/Add_New_MMS_USer.PNG" alt="">
            </div>
            <p>
                Start filling in and selecting all the required details on this page. Here are the explanations for some
                of the fields:<br>
                <b>CAI:</b> add user’s CAI<br>
                <b>Business Unit:</b> select which BU the user belongs to.<br>
                <b>Access Group Name:</b> by default, MMS_User is enabled. By adding user with MMS_User access group,
                you will allow the user to access MMS portal after SSO. Also, you can select MMS_Admin option to give
                the privilege to access MMSUser section in MMS portal. <br>
                <b>MMS Module Selection:</b><br>
                <b>SAR:</b> select the access group name(s) you want to provide to the user for SAR Module.<br>
                <b>IRIS:</b> select the access group name(s) you want to provide to the user for IRIS Module.

            </p>
            <div class="SubHeader" #irisFields>Selecting IRIS Access Groups and IRIS related fields</div>
            <p>For the IRIS Module, you can select one or many access groups to give the user the needed privileges. The
                following screen captures the available access groups.</p>
            <div>
                <img src="./../../../../../assets/Images/Selecting_IRIS_Details.PNG" alt="">
            </div>
            <p> Once you select access IRIS access group(s), an ‘IRIS’ section will be displayed where you can start
                selecting:<br>
                <b>IRIS DOA Level:</b> based on BU, you can select the DOA level that the user belongs to.<br>
                <b>User Asset Group(s):</b> based on BU, you can select one or many Asset Group(s) that the user belongs
                to.<br>
                <b>User Department(s):</b> you can select one or many department(s) that the user belongs to.
            </p>
        </div>

        <!-- Contact Parent Container -->
        <app-contact-support></app-contact-support>
    </div>
</section>