export const environment = {
  production: true,
  apiUrl: 'https://wombatmms-api-test.azure.chevron.com/',
  azureAd: {
    clientId: '19b97578-6b09-4729-a03e-0105c50e3ab9',
    redirectUri: 'https://wombatmms-test-cvx.azurewebsites.net/',
    authority:
      'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    postLogoutRedirectUri: 'https://wombatmms-test-cvx.azurewebsites.net/logout',
    graphApis: {
      users: 'https://graph.microsoft.com/v1.0/users',
      me: 'https://graph.microsoft.com/v1.0/me',
    },
    downStreamApis: {
      baseUri: 'https://wombatmms-api-test.azure.chevron.com/',
      scope: 'https://wombatmms-api-dev.azure.chevron.com/user_impersonation'
    },
  },
  IRIS: {
    urlPath: 'https://iris-test.azure.chevron.com/MMS_IRIS/MyIRIS',
    apiPath: 'https://iris-api-test-cvx.azurewebsites.net/',
    scope: 'https://iris-api-dev.azure.chevron.com//user_impersonation'
  }
};
