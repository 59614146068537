import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MMSUserRoutingConstants } from 'src/app/core/constants/MMS_User/mms-user-routing.constants';
import { MmsDashboardComponent } from './feature/mms/components/mms-dashboard/mms-dashboard.component';
import { MMSRoutingConstants } from './core/constants/MMS-routing-constants/mms-routing-constants';
import { MsalGuard } from '@azure/msal-angular';
import { InvalidPermissionsComponent } from './core/components/invalid-permissions/invalid-permissions.component';
import { ProfileComponent } from './core/components/profile/profile.component';

const routes: Routes = [
    {
        path: MMSRoutingConstants.MMSDashboard,
        loadChildren: () =>
            import('./feature/mms/module/mms-routing.module').then(
                (m) => m.MMSRoutingModule),
        canActivate: [MsalGuard]
    },
    {
        path: MMSUserRoutingConstants.MMSUserManagement,
        loadChildren: () => import('./feature/mms-user/modules/mmsuser-routing.module').then(m => m.MMSUserRoutingModule),
        canActivate: [MsalGuard]
    },
    {
        path: MMSRoutingConstants.UserGuide,
        loadChildren: () => import('./feature/user-guide/module/user-guide-routing.module').then(m => m.UserGuideRoutingModule),
        canActivate: [MsalGuard]
    },
    {
        path: MMSRoutingConstants.InvalidPermission,
        component: InvalidPermissionsComponent
    },
    {
        path: MMSRoutingConstants.profile,
        component: ProfileComponent,
    },
    {
        path: '',
        redirectTo: MMSRoutingConstants.MMSDashboard,
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule { }