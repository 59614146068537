<div class="SummaryContainer">
    <section>
        <kendo-panelbar>
            <kendo-panelbar-item>
                <ng-template kendoPanelBarItemTitle>
                    <div class="title" (click)="nav_HelpGettingStarted('')">Getting Started with MMS</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <div class="subTitle" (click)="nav_HelpGettingStarted('aboutMMS')">About MMS Portal</div>
                        <div class="subTitle" (click)="nav_HelpGettingStarted('accessingTool')">Accessing the Tool</div>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
            <kendo-panelbar-item>
                <ng-template kendoPanelBarItemTitle>
                    <div class="title" (click)="nav_ManageMMSUSers('')">Manage MMS Users</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <div class="subTitle" (click)="nav_ManageMMSUSers('mmsUser')">MMS User</div>
                        <div class="subTitle" (click)="nav_ManageMMSUSers('newUser')">Add new MMS User</div>
                        <div class="subTitle" (click)="nav_ManageMMSUSers('irisFields')">Selecting IRIS Access
                            Groups and <br>IRIS related fields</div>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
        </kendo-panelbar>
    </section>
</div>