import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { MMSUserService } from '../../../services/mms-user/mms-user.service';
import { GraphService } from 'src/app/core/services/graph.service';
import { GraphResponseType, ProfileType } from 'src/app/core/model/graph.models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MMSUsersConstant } from 'src/app/core/constants/MMS_User/user-manager/mms-users.constants';
import { SharedConst } from 'src/app/core/constants/shared.constants';
import { SupplierDto } from '../../../models/supplier.model';
import { SupplierService } from '../../../services/supplier/supplier.service';
import { BusinessUnitService } from '../../../services/business-unit-service/business-unit.service';
import { BusinessUnitDto } from '../../../models/bussinessunits.model';
import { GroupsDto } from '../../../models/groups.model';
import { AssetGroupsConst } from 'src/app/core/constants/MMS_User/asset-groups/asset-groups.constants';
import { MmsGroupService } from '../../../services/mms-group/mms-group.service';
import { DOALevelsService } from '../../../services/doa-levels/doalevels.service';
import { DOALevelDto } from '../../../models/doa-Level.model';
import { GroupsService } from '../../../services/groups/group.service';
import { DepartmetsService } from '../../../services/department/departmets.service';
import { DepartmentDto } from '../../../models/departments.model';
import { UserDto } from '../../../models/user.model';
import { AssetDto } from '../../../models/asset.model';
import { AssetService } from '../../../services/asset/asset.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-manager-details',
  templateUrl: './user-manager-details.component.html',
  styleUrls: ['./user-manager-details.component.scss']
})
export class UserManagerDetailsComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-]+\.[a-zA-z]{2,4}$/;
  heading: string = 'Add New User';
  searchedText: string = '';
  isExternalUser: boolean = false;
  isValidForm: boolean = true;
  emailAddress: string = '';
  requiredEmail: boolean = false;
  cai: string = '';
  hasCAIError: boolean = false;
  caiError: string = '';
  hasBUError: boolean = false;
  buError: string = 'Please select BU.';
  hasAccessGroupError: boolean = false;
  accessGroupError: string = AssetGroupsConst.assetGroupErrorMsg;
  mmsUserForm = new FormGroup({
    fcai: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
    businessUnitName: new FormControl('', Validators.required),
    doaLevel: new FormControl(''),
  });
  suppliers: SupplierDto[] = [];
  selectedSuppliers: SupplierDto[] = [];
  businessUnitList: BusinessUnitDto[] = [];
  businessUnit?: BusinessUnitDto;
  businessUnitId: number = 0;
  businessUnitName: string = '';
  groupsList: GroupsDto[] = [];
  selectedAccessGroupNames: GroupsDto[] = []
  accessCroupId: number = 0;
  iris_user_groups: GroupsDto[] = [];
  selected_iris_groups: GroupsDto[] = [];
  isJDEOnlyDOAChange: boolean = false;
  doaLevelList: DOALevelDto[] = [];
  doaLevelId: number = 0;
  assets: AssetDto[] = [];
  selectedAssets: AssetDto[] = [];
  departments: DepartmentDto[] = [];
  selectedDepartments: DepartmentDto[] = [];
  isNew: boolean = true;
  id: any;
  isActive: any;
  isAlreadyActive: boolean = false;
  name: any;
  user: UserDto = new UserDto();
  edited_user: UserDto = new UserDto();
  graphResponse?: ProfileType;
  userList: UserDto[] = [];
  userRolesStr: string = '';
  doaLevelDisplayName: string = '';
  endoserCount_GOMBU: number = 0;
  MMS_IRIS_Endorser: string = 'MMS_IRIS_Endorser';
  isEndorser: boolean = false;
  isAlreadyInactiveEndorser: boolean = false;
  constructor(private commonService: CommonService, private router: Router, private route: ActivatedRoute, private mmsUserService: MMSUserService,
    private graphService: GraphService, private renderer: Renderer2, private el: ElementRef, private supplierService: SupplierService,
    private businessUnitService: BusinessUnitService, private mmsGroupService: MmsGroupService, private doaLevelsService: DOALevelsService,
    private groupsService: GroupsService, private assetService: AssetService, private departmetsService: DepartmetsService) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.isNew = stateData['isNew'];
      this.id = stateData['id'];
      if (!this.isNew) {
        this.businessUnitId = stateData['businessUnitId'];
      }
      this.searchedText = stateData['searchedText'];
      sessionStorage.setItem('MMSGroupDetails', JSON.stringify(stateData));
    }
    else {
      let sessionData: any = JSON.parse(sessionStorage.getItem('MMSGroupDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionData)) {
        this.isNew = sessionData['isNew'];
        this.id = sessionData['id'];
        if (!this.isNew) {
          this.businessUnitId = sessionData['businessUnitId'];
        }
        this.searchedText = sessionData['searchedText'];
      }
    }
  }
  ngOnInit(): void {
    if (this.isAdmin()) {
      this.getDropdownsDetail();
      //this.getEndoserCount_GOMBU();
      if (this.isNew) {
        this.getUserList();
      }
      if (!this.isNew) {
        this.getExistingData();
      }
    }
    else {
      this.router.navigate(['']);
    }
  }

  isAdmin(): boolean {
    let roles: string = this.commonService.getUserRoles();
    if (!this.commonService.isNullOrEmptyOrUndefinedOnly(roles)) {
      let user_roles: string[] = roles.split(',').filter((role: string) => role === RoleConstant.Admin);
      if (user_roles.length > 0)
        return true;
      else
        return false;
    }
    return false;
  }

  getDropdownsDetail() {
    let businessUnitAPI = this.businessUnitService.getAllBUNames();
    let AccessGroupsAPI = this.groupsService.getAccessGroupList();
    let IRIS_Access_Group_API = this.groupsService.getIRISAccessGroupList();
    forkJoin([businessUnitAPI, AccessGroupsAPI, IRIS_Access_Group_API]).subscribe(([businessUnitList, access_GroupsList, IRIS_Access_Group]) => {
      this.businessUnitList = Object.assign([], businessUnitList);
      this.groupsList = access_GroupsList;
      if (this.isNew) {
        let MMS_USER = access_GroupsList.find((userGrp: GroupsDto) => (userGrp.name === 'MMS_User'));
        MMS_USER ? this.selectedAccessGroupNames.push(MMS_USER) : '';
      }
      this.iris_user_groups = Object.assign([], IRIS_Access_Group);
    });
  }

  getEndoserCount_GOMBU() {
    this.mmsUserService.checkEndoserCount().subscribe((count: number) => {
      this.endoserCount_GOMBU = count;
    })
  }
  getUserList() {
    this.mmsUserService.getMMSUserList().subscribe((userList: UserDto[]) => {
      this.userList = userList;
    })
  }
  getExistingData() {
    let userAPI = this.mmsUserService.getUserById(this.id);
    let departmentAPI = this.departmetsService.getDepartmentsRecord();
    let assetAPI = this.assetService.getAssetsListByBUId(this.businessUnitId);
    let doaLevelAPI = this.doaLevelsService.getDOALevelsbyBuId(this.businessUnitId);
    forkJoin([userAPI, doaLevelAPI, assetAPI, departmentAPI]).subscribe(([user, doaLevels, AssetsList, departmentList]) => {
      this.user = user;
      let doaLevelData = Object.assign([], doaLevels);
      this.doaLevelList = doaLevelData.filter((doaLevel: DOALevelDto) => (doaLevel.isActive === true)).sort((a: DOALevelDto, b: DOALevelDto) => a.doaLevel - b.doaLevel);
      this.getDOADisplayName();
      this.assets = Object.assign([], AssetsList);
      this.departments = Object.assign([], departmentList);
      this.isActive = this.user.isActive;
      this.isAlreadyActive = this.isActive;
      this.businessUnitId = this.user.businessUnitId;
      this.businessUnitName = this.user.businessUnitName!;
      this.cai = this.user.cai!;
      this.name = this.user.name!;
      this.heading = this.name;
      this.userRolesStr = this.user.rolesDto ? this.user.rolesDto.map((x) => { return x.name; }).join(', ') : '';
      if (this.user.doaLevelDtos) {
        this.doaLevelId = this.user.doaLevelDtos.id;
        this.user.doaLevelDtos.displayName = this.user.doaLevelDtos.label + ' ( $' + this.user.doaLevelDtos.doaLevel + ' ' + this.user.doaLevelDtos.currancy + ' )';
        this.doaLevelDisplayName = this.user.doaLevelDtos.displayName;
      }
      this.mmsUserForm.setValue({
        fcai: this.cai,
        businessUnitName: this.user.businessUnitName!,
        doaLevel: this.user.doaLevelDtos ? this.user.doaLevelDtos.displayName! : '-- Select DOA Level --',
      });
      this.selectedAccessGroupNames = this.user.accessGroupsDto ? this.user.accessGroupsDto?.filter((accessGroup: GroupsDto) => (!accessGroup.name?.includes('IRIS'))) : [];
      this.selected_iris_groups = this.user.irisAccessgroupsDto ? this.user.irisAccessgroupsDto : [];
      this.isEndorser = this.selected_iris_groups.findIndex((group: GroupsDto) => (group.name === this.MMS_IRIS_Endorser)) === -1 ? false : true;
      this.selectedAssets = this.user.assetGroupDto ? this.user.assetGroupDto : [];
      this.selectedDepartments = this.user.departmentdto ? this.user.departmentdto : [];
    });
  }

  action(actionName: string) {
    if (actionName === 'cancel') {
      this.navBack();
    }
    else if (actionName === 'save') {
      this.validateForm();
      if (!this.isValidForm) {
        this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center')
      }
      else if (this.isNew && this.isExistingCAI()) {
        this.commonService.showNotificationMessage(MMSUsersConstant.existingUserMsg, 'error', 'center')
      }
      else {
        if (this.isNew) {
          this.setNewUserDetails();
          this.mmsUserService.addUser(this.user).subscribe((user: UserDto) => {
            this.commonService.showNotificationMessage(MMSUsersConstant.successfullAddMsg, 'success', 'center')
            this.navBack();
          });
        }
        else {
          this.setExistingUserDetails();
          this.mmsUserService.editUser(this.id, this.edited_user).subscribe((user: UserDto) => {
            this.commonService.showNotificationMessage(MMSUsersConstant.successfullEditMsg, 'success', 'center');
            this.navBack();
          });
        }
      }
    }
  }
  validateForm() {
    this.clearValidation();
    if (this.isExternalUser) {
      this.validateExternalUser();
    }
    else {
      this.validateInternalUser();
    }
  }
  validateInternalUser() {
    this.isNew ? this.handleCAIValidation() : '';
    if (this.businessUnitId === 0) {
      this.hasBUError = true;
      this.isValidForm = false;
    }
    if (this.selectedAccessGroupNames.length === 0) {
      this.hasAccessGroupError = true;
      this.isValidForm = false
    }
  }
  validateExternalUser() {
    if (this.emailAddress.trim() === '' || !this.emailPattern.test(this.emailAddress)) {
      this.requiredEmail = true;
      this.isValidForm = false;
    }
  }
  clearValidation() {
    this.isValidForm = true;
    this.requiredEmail = false;
    this.hasCAIError = false;
    this.hasBUError = false;
    this.hasAccessGroupError = false;
  }

  isExistingCAI() {
    let list: UserDto[] = this.userList.filter((user: UserDto) => (user.cai?.toUpperCase() === this.cai.toUpperCase()));
    return list.length > 0 ? true : false;
  }
  navBack() {
    this.router.navigate([`../`], { state: { searchedText: this.searchedText }, relativeTo: this.route });
  }
  setNewUserDetails() {
    if (!this.isExternalUser) {
      this.user.id = 0;
      this.user.name = this.graphResponse!.givenName + ' ' + this.graphResponse!.surname;
      this.user.email = this.graphResponse!.userPrincipalName;
      this.user.cai = this.cai;
      this.user.externalId = this.graphResponse!.id;
      this.user.businessUnitId = this.businessUnitId;
      //this.user.lastLogin=new Date();
      this.user.isActive = true;
      this.user.order = 0;
      this.user.createdDateTime = new Date;
      this.user.createdBy = this.commonService.getUserEmailId();
      this.user.accessGroupIds = this.selectedAccessGroupNames.map((group: GroupsDto) => { return group.id });
      this.user.mmsIrisGroupId = this.selected_iris_groups.map((group: GroupsDto) => { return group.id! });
      this.user.irisDOALevelId = this.doaLevelId;
      this.user.departmentId = this.selectedDepartments.map((dep: DepartmentDto) => { return dep.id! });
      this.user.userAssetGroupsId = this.selectedAssets.map((asset: AssetDto) => { return asset.id! });
    }
    else {
    }

  }
  setExistingUserDetails() {
    if (!this.isExternalUser) {
      this.edited_user.id = this.id;
      this.edited_user.name = this.user.name;
      this.edited_user.email = this.user.email;
      this.edited_user.cai = this.cai;
      this.edited_user.externalId = this.user.externalId;
      this.edited_user.businessUnitId = this.businessUnitId;
      //this.user.lastLogin=new Date();
      this.edited_user.isActive = this.isActive;
      this.edited_user.order = this.user.order;
      this.edited_user.modifiedBy = this.commonService.getUserEmailId();
      this.edited_user.modifiedDateTime = new Date();
      this.edited_user.accessGroupIds = this.selectedAccessGroupNames.map((data: GroupsDto) => { return data.id! });
      this.selected_iris_groups.map((data: GroupsDto) => { this.edited_user.accessGroupIds.push(data.id!) });
      //this.edited_user.mmsIrisGroupId = this.selected_iris_groups.map((data: GroupsDto) => { return data.id! });
      this.edited_user.irisDOALevelId = this.doaLevelId;
      this.edited_user.departmentId = this.selectedDepartments.map((dep: DepartmentDto) => { return dep.id! });
      this.edited_user.userAssetGroupsId = this.selectedAssets.map((asset: AssetDto) => { return asset.id! });
    }
    else {
    }
  }
  onUserSwichChange(isExternalUser: boolean) {
    this.clearValidation();
    if (isExternalUser) {
      this.getSupplierList()
    }
    else {

    }
  }
  getSupplierList() {
    this.suppliers = this.supplierService.getSupplierMockList();
  }

  handleBlur(): void {
    const cai = <string>this.mmsUserForm.get('fcai')?.value;
    this.cai = cai.toUpperCase();
    if (cai.length === 4) {
      this.graphService.getAdUserNameByCAI(cai).subscribe((response: GraphResponseType) => {
        if (response && response.value && response.value.length === 0) {
          this.setCAIErrors(true, MMSUsersConstant.invalidCAIError);
          return;
        }
        this.graphResponse = response.value[0];
        this.setCAIErrors(false);
      })
    }
    else {
      this.handleCAIValidation();
    }
  }

  setCAIErrors(val: boolean, error?: string) {
    this.hasCAIError = val;
    this.isValidForm = !val;
    if (error) {
      this.caiError = error;
      this.mmsUserForm.controls.fcai.setErrors({ 'incorrect': val });
    }
  }

  handleCAIValidation() {
    const cai = <string>this.mmsUserForm.get('fcai')?.value;
    if (!this.mmsUserForm.get('fcai')?.touched && cai.length == 0) {
      this.setCAIErrors(true, MMSUsersConstant.caiError);
      return;
    }
    if (this.mmsUserForm.get('fcai')?.touched && cai.length == 0) {
      this.setCAIErrors(true, MMSUsersConstant.caiError);
      return;
    }
    if (this.mmsUserForm.get('fcai')?.touched && cai.length !== 4) {
      this.setCAIErrors(true, MMSUsersConstant.caiLengthError);
      return;
    }
    this.setCAIErrors(false);
    this.mmsUserForm.get('fcai')?.setErrors(null);
  }

  selectBusinessUnit(value: BusinessUnitDto) {
    if (value && value.id) {
      this.businessUnitId = value.id;
      this.businessUnitName = value.name!;
      if (!this.isNew && this.businessUnitId === this.user.businessUnitId) {
        this.getIRISData(true, false, false);
      }
      else {
        this.getIRISData(false, true, false);
      }
    }
    else {
      this.businessUnitId = 0;
      this.businessUnitName = '-- Select Business Unit --';
      this.getIRISData(false, true, false);
    }
    // if (!this.checkEndorser_GOMBU(this.selected_iris_groups)) {
    //   this.selected_iris_groups = this.selected_iris_groups.filter((group: GroupsDto) => (group.name !== this.MMS_IRIS_Endorser));
    // }
  }

  getDOADisplayName() {
    this.doaLevelList.forEach((doaLevel: DOALevelDto) => {
      doaLevel.displayName = doaLevel.label + ' ( $' + doaLevel.doaLevel + ' ' + doaLevel.currancy + ' )';
    })
  }

  selectAccessGroup(values: GroupsDto[]) {
    this.selectedAccessGroupNames = values;
  }

  selectIRISUserGroups(value: GroupsDto[]) {
    //if (this.checkEndorser_GOMBU(value)) {
      this.selected_iris_groups = value;
      if (value.length > 0) {
        if (!this.isNew && this.businessUnitId === this.user.businessUnitId) {
          this.getIRISData(true, false, true);
        }
        else {
          this.getIRISData(false, false, true);
        }

      }
      else {
        this.doaLevelId = 0;
        this.setUserForm_defaultDOALEvel();
      }
    // }
    // else {
    //   this.selected_iris_groups = this.selected_iris_groups.filter((group: GroupsDto) => (group.name !== this.MMS_IRIS_Endorser));;
    // }
  }

  getIRISData(isOldBU: boolean, isNewBUSelection: boolean, isNewIRISelection: boolean) {
    if (this.businessUnitId !== 0) {
      let departmentAPI = this.departmetsService.getDepartmentsRecord();
      let assetAPI = this.assetService.getAssetsListByBUId(this.businessUnitId);
      let doaLevelAPI = this.doaLevelsService.getDOALevelsbyBuId(this.businessUnitId);
      forkJoin([doaLevelAPI, assetAPI, departmentAPI]).subscribe(([doaLevels, AssetsList, departmentList]) => {
        this.doaLevelList = doaLevels.filter((doaLevel: DOALevelDto) => (doaLevel.isActive === true)).sort((a, b) => a.doaLevel - b.doaLevel);
        this.getDOADisplayName();
        this.assets = Object.assign([], AssetsList);
        this.departments = Object.assign([], departmentList);
        if (this.isNew) {
          this.selectedAssets = Object.assign([], AssetsList);
          this.selectedDepartments = Object.assign([], departmentList);
          if (isNewBUSelection) {
            this.doaLevelId = 0;
            this.setUserForm_defaultDOALEvel();
          }
        }
        else {
          if (isOldBU) {
            this.selectedAssets = this.user.assetGroupDto ? this.user.assetGroupDto : [];
            this.doaLevelId = this.user.doaLevelDtos ? this.user.doaLevelDtos.id : 0;
            this.mmsUserForm.setValue({
              fcai: this.cai,
              businessUnitName: this.businessUnitName,
              doaLevel: this.doaLevelDisplayName
            });
          }
          else if (isNewBUSelection || isNewIRISelection) {
            this.selectedAssets = Object.assign([], AssetsList);
            if (isNewBUSelection) {
              this.doaLevelId = 0;
              this.setUserForm_defaultDOALEvel();
            }
          }
          this.selectedDepartments = this.user.departmentdto ? this.user.departmentdto : [];
        }
      })
    }
    else {
      this.doaLevelId = 0;
      this.setUserForm_defaultDOALEvel();
      this.doaLevelList = []
      this.assets = [];
      this.selectedAssets = [];
      this.departmetsService.getDepartmentsRecord().subscribe((departmentList: DepartmentDto[]) => {
        this.departments = Object.assign([], departmentList);
        this.selectedDepartments = Object.assign([], departmentList);
      });
    };
  }
  setUserForm_defaultDOALEvel() {
    this.mmsUserForm.setValue({
      fcai: this.cai,
      businessUnitName: this.businessUnitName,
      doaLevel: '-- Select DOA Level --'
    });
  }
  selectDOALevel(value: DOALevelDto) {
    this.doaLevelId = value.id;
  }

  selectAssets(value: AssetDto[]) {
    this.selectedAssets = value;
  }

  selectDepartements(value: DepartmentDto[]) {
    this.selectedDepartments = value;
  }

  selectSuppliers(value: SupplierDto[]) {
    this.selectedSuppliers = value;
  }
  onJDEOnlyDOAChange(value: boolean) {
  }

  checkEndorser_GOMBU(selected_iris_groups: GroupsDto[]): boolean {
    let endorserIndex = selected_iris_groups.findIndex((group: GroupsDto) => (group.name === this.MMS_IRIS_Endorser));
    if (this.businessUnitName.includes('GOMBU') && endorserIndex !== -1) {
      if (this.endoserCount_GOMBU >= 2) {
        if (this.isNew || !this.isEndorser || this.isAlreadyInactiveEndorser) {
          this.commonService.showNotificationMessage(MMSUsersConstant.limitEndorsersMsg, 'error', 'center');
          return false;
        }
        else if (!this.isNew && this.isEndorser && !this.isAlreadyActive) {
          this.commonService.showNotificationMessage(MMSUsersConstant.inactiveEndorserMsg, 'error', 'center');
          return false;
        }
      }
    }
    return true;
  }
  onActiveChange() {
    // if (!this.isNew && this.isEndorser && !this.isAlreadyActive) {
    //   if (!this.checkEndorser_GOMBU(this.selected_iris_groups)) {
    //     this.isActive = !this.isActive;
    //   }
    //   else {
    //     this.isAlreadyActive = true;
    //     this.isAlreadyInactiveEndorser = true;
    //   }
    // }
  }
}
